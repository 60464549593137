var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('h2',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.customTitle ? _vm.customTitle : _vm.$t('users.traders' ))+" ")]),(!_vm.articleId)?_c('div',[(!_vm.groupId && !_vm.articleId)?_c('v-btn',{staticClass:"float-right mr-10 white--text",attrs:{"color":"blue"},on:{"click":function($event){return _vm.updateGroup()}}},[_vm._v(" Dodaj do grupy "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-account-group")])],1):_c('v-btn',{staticClass:"float-right mr-10 white--text",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteFromGroup()}}},[_vm._v(" Usuń z grupy "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-account-group")])],1)],1):_vm._e(),_c('v-btn',{staticClass:"float-right mr-10 white--text",attrs:{"color":"green"},on:{"click":function($event){return _vm.displayFilters()}}},[_vm._v(" Filtruj "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-filter-plus-outline")])],1),_c('div',{staticClass:"mb-5",staticStyle:{"clear":"both"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.traders,"show-select":_vm.articleId ? false : true,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"footer-props":{
        'items-per-page-options': [10, 15, 20, 25, 50, -1]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.isFiltersVisible)?{key:"header.login",fn:function(ref){
      var header = ref.header;
return [_c('header-filter',{attrs:{"text":header.text},model:{value:(_vm.filters.login),callback:function ($$v) {_vm.$set(_vm.filters, "login", $$v)},expression:"filters.login"}})]}}:null,(_vm.isFiltersVisible)?{key:"header.name",fn:function(ref){
      var header = ref.header;
return [_c('header-filter',{attrs:{"text":header.text},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})]}}:null,(_vm.isFiltersVisible)?{key:"header.type",fn:function(ref){
      var header = ref.header;
return [_c('header-filter',{attrs:{"text":header.text,"items":[
          {name:'Test', id:0},
          {name:'Detal', id:1},
          {name:'Mix', id:2},
          {name:'Hrc', id:3}
          ],"type":1},model:{value:(_vm.filters.types),callback:function ($$v) {_vm.$set(_vm.filters, "types", $$v)},expression:"filters.types"}})]}}:null,(_vm.isFiltersVisible)?{key:"header.phone",fn:function(ref){
          var header = ref.header;
return [_c('header-filter',{attrs:{"text":header.text},model:{value:(_vm.filters.phone),callback:function ($$v) {_vm.$set(_vm.filters, "phone", $$v)},expression:"filters.phone"}})]}}:null,(_vm.isFiltersVisible)?{key:"header.retailName",fn:function(ref){
          var header = ref.header;
return [_c('header-filter',{attrs:{"text":header.text},model:{value:(_vm.filters.retailName),callback:function ($$v) {_vm.$set(_vm.filters, "retailName", $$v)},expression:"filters.retailName"}})]}}:null,(_vm.isFiltersVisible)?{key:"header.groupNames",fn:function(ref){
          var header = ref.header;
return [_c('header-filter',{attrs:{"text":header.text},model:{value:(_vm.filters.groupName),callback:function ($$v) {_vm.$set(_vm.filters, "groupName", $$v)},expression:"filters.groupName"}})]}}:null,{key:"item.actions",fn:function(props){return (!_vm.articleId)?[_c('v-btn',{class:("white--text mx-2  " + (!props.item.hasToken ? 'token-not-exist' : 'token-exist')),staticStyle:{"color":"white !important"},attrs:{"disabled":!props.item.hasToken,"icon":""},on:{"click":function($event){return _vm.deleteTraderTokenDialog(props.item)}}},[_c('v-icon',{staticStyle:{"color":"white !important"}},[_vm._v("mdi-logout")])],1)]:undefined}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('add-to-group-dialog',{ref:"AddToGroupDialog",attrs:{"tradersIds":_vm.selectedTradersIds}}),_c('remove-from-group-dialog',{ref:"confirmRemoveFromGroupDialog",attrs:{"endPoint":"/TraderGroup/DeleteFromGroup"},on:{"submit":_vm.getDataAsync}}),_c('remove-from-group-dialog',{ref:"confirmRemoveFromGroupDialog",attrs:{"customUrl":true,"endPoint":"/TraderGroup/DeleteFromGroup"},on:{"submit":_vm.getDataAsync}}),_c('confirm-delete-dialog',{ref:"confirmDeleteDialog",attrs:{"isMobileApi":true,"endPoint":"Authentication/TraderLogout","titleActionText":"Potwierdź wylogowanie","descriptionActionText":"Czy napewno chcesz wylogować tego użytkownika?","successActionText":"Poprawnie wylogowano"},on:{"submit":_vm.getDataAsync}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }