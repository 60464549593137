
<template>
    <div v-if="(type === 0)">
        <v-text-field
            v-model="inputVal"
            @click.native.capture="stopPropagation"
            persistent-placeholder
            validate-on-blur
        />
        {{ text }}
    </div>
    <div v-else-if="(type === 1)">
        <v-autocomplete
        style="margin-top:5px"
          @click.native.capture="stopPropagation"
          @change="searchInput=''"
          :search-input.sync="searchInput"
          multiple
          :items="items"
          label:text
          :item-text="'name'"
          :item-value="'id'"
          v-model='inputVal'
        />
        {{ text }}
    </div>
</template>
<script>

export default {
  props: {
    type: {
      type: Number,
      default: 0
    },
    items: {
      type: Array
    },
    text: {
      type: String,
      default: ''
    }
  },
  name: 'header-filter',
  data () {
    return {
      searchInput: ''
    }
  },
  methods: {
    stopPropagation (event) {
      event.stopPropagation()
    }
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}

</script>
<style>
div.v-input__append-inner{
  cursor:pointer !important;
}
</style>
