<template>
    <div>
        <v-card>
            <h2 class="pl-2">
                {{ customTitle ? customTitle : $t('users.traders' )}}
            </h2>

            <div v-if="!articleId">

              <v-btn
              v-if="!groupId && !articleId"
              color="blue"
              class="float-right mr-10 white--text"
              @click="updateGroup()"
            >
            Dodaj do grupy
            <v-icon class="ml-2">mdi-account-group</v-icon>
            </v-btn>
            <v-btn
              color="red"
              v-else
              class="float-right mr-10 white--text"
              @click="deleteFromGroup()"
            >
            Usuń z grupy
            <v-icon class="ml-2">mdi-account-group</v-icon>
            </v-btn>
            </div>

            <v-btn
            @click="displayFilters()"
              color="green"
              class="float-right mr-10 white--text"
          >
            Filtruj
            <v-icon class="ml-2">mdi-filter-plus-outline</v-icon>
          </v-btn>

          <div style="clear:both" class="mb-5"></div>

        <v-data-table
          v-model=selectedItems
          :headers=headers
          :items=traders
          :show-select="articleId ? false : true"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25, 50, -1]
          }"
        >
        <template
          v-if="isFiltersVisible"
          v-slot:header.login="{ header }">
          <header-filter
            v-model="filters.login"
            :text="header.text">
          </header-filter>
        </template>

        <template
          v-if="isFiltersVisible"
          v-slot:header.name="{ header }">
          <header-filter
            v-model=filters.name
            :text="header.text">
          </header-filter>
        </template>

        <template v-if="isFiltersVisible"
          v-slot:header.type="{ header }">
          <header-filter
            v-model=filters.types
            :text="header.text"
            :items="[
              {name:'Test', id:0},
              {name:'Detal', id:1},
              {name:'Mix', id:2},
              {name:'Hrc', id:3}
              ]"
            :type="1">
          </header-filter>
        </template>

        <template
          v-if="isFiltersVisible"
          v-slot:header.phone="{ header }">
          <header-filter
            v-model=filters.phone
            :text="header.text">
          </header-filter>
        </template>

        <template
          v-if="isFiltersVisible"
          v-slot:header.retailName="{ header }">
          <header-filter
            v-model=filters.retailName
            :text="header.text">
          </header-filter>
        </template>

        <template
          v-if="isFiltersVisible"
          v-slot:header.groupNames="{ header }">
          <header-filter
            v-model=filters.groupName
            :text="header.text">
          </header-filter>
        </template>

        <template v-if="!articleId" slot="item.actions" slot-scope="props" class="justify-center white--text">
            <v-btn
              :disabled="!props.item.hasToken"
              style="color:white !important;"
              :class="`white--text mx-2  ${!props.item.hasToken ? 'token-not-exist' : 'token-exist'}`"
              icon
              @click="deleteTraderTokenDialog(props.item)"
            ><v-icon
              style="color:white !important;">mdi-logout</v-icon>
            </v-btn>
        </template>

        </v-data-table>
        </v-card>

        <add-to-group-dialog
          ref="AddToGroupDialog"
          :tradersIds="selectedTradersIds"
        ></add-to-group-dialog>

        <remove-from-group-dialog
          endPoint="/TraderGroup/DeleteFromGroup"
          ref="confirmRemoveFromGroupDialog"
          @submit="getDataAsync"
        ></remove-from-group-dialog>

        <remove-from-group-dialog
          :customUrl="true"
          endPoint="/TraderGroup/DeleteFromGroup"
          ref="confirmRemoveFromGroupDialog"
          @submit="getDataAsync"
        ></remove-from-group-dialog>

        <confirm-delete-dialog
          :isMobileApi="true"
          endPoint="Authentication/TraderLogout"
          titleActionText="Potwierdź wylogowanie"
          descriptionActionText="Czy napewno chcesz wylogować tego użytkownika?"
          successActionText="Poprawnie wylogowano"
          ref="confirmDeleteDialog"
          @submit="getDataAsync"
        ></confirm-delete-dialog>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'
import AddToGroupDialog from '@/views/UserManagement/UserGroupDialogs/AddUsersToGroupDialog.vue'
import RemoveFromGroupDialog from '@/views/UserManagement/UserGroupDialogs/RemoveFromGroupDialog.vue'
import DataTableHeaderFilter from '@/components/DataTableHeaderFilter.vue'
import moment from 'moment'

import { displayErrors } from '@/utils/helpers'
export default {
  props: {
    groupId: {
      typeof: String,
      default: null
    },
    articleId: {
      typeof: String,
      default: null
    },
    customTitle: {
      typeof: String,
      default: null
    }
  },
  name: 'Traders',
  components: {
    ConfirmDeleteDialog,
    AddToGroupDialog,
    RemoveFromGroupDialog,
    'header-filter': DataTableHeaderFilter
  },
  data () {
    return {
      traders: [],
      headers: [
        { text: this.$t('users.login'), value: 'login' },
        { text: this.$t('users.name'), value: 'name' },
        { text: 'Typ', value: 'type', sortable: true },
        { text: this.$t('users.retailName'), value: 'retailName' },
        { text: 'Grupa', value: 'groupNames' },
        { text: this.$t('users.phone'), value: 'phone' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '10%' }
      ],
      selectedItems: [],
      endpoint: '',
      isFiltersVisible: false,
      filters: {
        login: '',
        name: '',
        phone: '',
        retailName: '',
        types: [],
        groupName: ''
      },
      options: {},
      serverItemsLength: 0
    }
  },
  async beforeMount () {
    if (this.articleId) {
      this.headers.unshift({ text: 'Data i godzina wyświetlenia', value: 'visitedDate', sortable: false, width: '20%' })
    }

    this.endpoint = '/Trader/GetTraders'
  },
  methods: {
    async getDataAsync () {
      this.selectedItems = []

      const result = await this.$http.post(
        this.endpoint,
        {
          articleId: this.articleId,
          groupId: this.groupId,
          filters: this.filters,
          options: this.options
        }
      )

      this.traders = result.data.results

      if (this.articleId) {
        this.traders.map(x => {
          x.visitedDate = moment.utc(x.visitedDate).locale('pl').local().format('L LT')
        })
      }

      this.serverItemsLength = result.data.totalCount
    },
    validateSelectedItems () {
      let result = true
      if (this.selectedItems.length < 1) {
        displayErrors('Brak wybranych przedstawicieli')
        result = false
      }

      return result
    },
    updateGroup () {
      if (this.validateSelectedItems()) {
        this.$refs.AddToGroupDialog.dialog = true
      }
    },
    deleteFromGroup () {
      if (this.validateSelectedItems()) {
        this.$refs.confirmRemoveFromGroupDialog.model = {
          groupId: this.groupId,
          tradersIds: this.selectedTradersIds
        }
        this.$refs.confirmRemoveFromGroupDialog.dialog = true
      }
    },
    displayFilters () {
      this.isFiltersVisible = !this.isFiltersVisible
      this.filters = {
        login: '',
        name: '',
        phone: '',
        retailName: ''
      }
    },
    deleteTraderTokenDialog (item) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = item.name
      this.$refs.confirmDeleteDialog.id = item.id
    }
  },
  computed: {
    selectedTradersIds () {
      return this.selectedItems.map(x => x.id)
    }
  },
  watch: {
    filters: {
      async handler (val) {
        await this.getDataAsync()
      },
      deep: true
    },
    options: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
<style>
  .token-not-exist {
    background-color: #d65959;
  }

  .token-exist {
    background-color: #20e020;
    transition: 0.3s;

  }
  .token-exist:hover{
    background-color: #38ff38;
  }
</style>
