<template>
    <v-row justify="end" class="mr-10">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ $t('groups.selectGroup') }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-select
                    ref="groupSelect"
                    :items="groups"
                    label="Grupa"
                    :item-text="'name'"
                    :item-value="'id'"
                    required
                    :rules="requiredRule"
                    v-model='groupId'
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
            {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submit"
            >
            {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
import { displayErrors, displayInfo } from '@/utils/helpers'

export default {
  name: 'add-to-group-dialog',
  props: {
    tradersIds: {
      typeof: Array
    }
  },
  data: () => ({
    dialog: false,
    groups: [],
    groupId: '',
    requiredRule: [
      v => !!v || 'Pole wymagane'
    ]
  }
  ),
  async mounted () {
    const result = await this.$http.get('/Group/GetGroups')
    this.groups = result.data
  },
  methods: {
    async submit () {
      if (!this.$refs.groupSelect.validate()) {
        displayErrors(this.$t('groups.selectGroup'))
        return
      }

      const result = await this.$http.post('/TraderGroup/AddToGroup',
        {
          groupId: this.groupId,
          tradersIds: this.tradersIds
        }
      )

      if (result.status === 200) {
        this.dialog = false
        displayInfo(this.$t('groups.addTraderComplite'))
      }
    }
  }
}
</script>
