<template>
  <v-row justify="center">

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Potwierdź usunięcie
        </v-card-title>

        <v-card-text>
          Czy napewno chcesz usunąć z grupy {{tradersToRemoveLength}} przedstawicieli?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Anuluj
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="submit"
          >
            Tak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { displayInfo } from '@/utils/helpers'
export default {
  name: 'remove-from-group-dialog',
  data () {
    return {
      dialog: false,
      model: {
        groupId: '',
        tradersIds: []
      }
    }
  },
  methods: {
    async submit () {
      const result = await this.$http.post('/TraderGroup/DeleteFromGroup',
        this.model
      )

      if (result.status === 200) {
        this.$emit('submit')
        this.dialog = false
        displayInfo('Poprawnie usunięto')
      }
    }
  },
  computed: {
    tradersToRemoveLength () {
      return this.model && this.model.tradersIds ? this.model?.tradersIds?.length : 0
    }
  }
}
</script>
